.check-text-fix{
    margin-top: 0.7rem!important;
}

.react-datetime-picker__wrapper{
    border: none !important;
}

.form-label, .col-form-label {
    font-weight: bold;
    font-size: 1rem;
}