@page {
    size: A4;

    @bottom-left {
      margin: 10pt 0 30pt 0;
      border-top: .25pt solid #666;
      content: "Change Hua Hin Real Estate";
      font-size: 9pt;
      color: #333;
    }
}

.check-text-fix{
    margin-top: 0.7rem!important;
}

.react-datetime-picker__wrapper{
    border: none !important;
}

.ql-container{
    font-size: 16px !important;
}

.ql-editor{
    color: #21325b !important;
}

.ql-editor p{
    margin-bottom: 20px !important;
}


.iciELI{
    max-width: 100% !important;
}

.file-types {
    max-width: 100% !important;
}




.pinClass {

    position: 'absolute';
    width: 40px;
    height: 40px;
    left: 20px;
    top: 20px;
    border: '1px solid #f44336';
    border-radius: 4px;
    background-color: 'white';
    text-align: 'center';
    color: '#3f51b5';
    font-size: 16px;
    font-weight: 'bold';
    padding: 4;
}


.progress {
    background-color: #fff !important;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
}