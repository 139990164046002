.property-icon-header{
    max-height: 20px;
    max-width: 20px;
    margin-right: 6px;
}

.form-label{
    white-space: nowrap;
    margin-right: 16px;
}

.check-text-fix{
    margin-top: 0.7rem!important;
}

.react-datetime-picker__wrapper{
    border: none !important;
}

.ql-container{
    font-size: 16px !important;
}

.ql-editor{
    color: #21325b !important;
}

.ql-editor p{
    margin-bottom: 20px !important;
}


.iciELI{
    max-width: 100% !important;
}

.file-types {
    max-width: 100% !important;
}

.eyebrow {
    display: inline-block;
    font-size: 0.7rem;
    font-weight: 500;
    text-transform: uppercase;
  }

.card-body-pdf{
    padding: 0.5rem 0.5rem;
}


.pinClass {

    position: 'absolute';
    width: 40px;
    height: 40px;
    left: 20px;
    top: 20px;
    border: '1px solid #f44336';
    border-radius: 4px;
    background-color: 'white';
    text-align: 'center';
    color: '#3f51b5';
    font-size: 16px;
    font-weight: 'bold';
    padding: 4;
}


.progress {
    background-color: #fff !important;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
}